import React from "react";
import './styles.css'


const CardsLayoutPanel = () => {

    return (
        <div className="custom-card-layout">
            <div className="position-1">Position 1</div>
            <div className="position-2">Position 2</div>
            <div className="position-3">Position 3</div>
        </div>
    );

};

export default CardsLayoutPanel;
