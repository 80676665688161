import React, { useState, useEffect, useRef } from "react";
import { useApolloClient } from '@apollo/react-hooks';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent, DateTimePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
    CheckBoxComponent,
    RadioButtonComponent,
    ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import { gql } from "@apollo/client";
import { Player } from '@lottiefiles/react-lottie-player';
import { getLocaleDateString } from '../../../utils/getLocaleDateString';


const Input = ({ value, queryDropdown, onChange, type, data_type, ...rest }) => {
    console.log(value)
    const client = useApolloClient();
    const datePickerRef = useRef(null);
    const userTimeZone = JSON.parse(localStorage.getItem('timezone'));
    const comboBoxRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [gridList, setGridList] = useState('');
    const [fields, setFields] = useState({ value: '', text: '', });

    if (rest.column_default && rest.column_default.includes('@')) {
        rest.column_default = userTimeZone;
    }

    const _getDynamicScreen = async () => {
        setIsLoading(true);
        // setError(null);
        try {
            let screenQuery = queryDropdown.replace('@v_locale', 'en')
            screenQuery = screenQuery.substring(1)
            const { data } = await client.query({ query: gql`${screenQuery}` });
            const queryName = rest.query_name;

            setFields({ value: rest.query_key, text: rest.query_value });
            setGridList(data[queryName]);
        } catch (e) {
            setError(e);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (queryDropdown !== null) {
            _getDynamicScreen()
        }

        return () => {
            setGridList('');
            setFields({ value: '', text: '', });
            setError(null);
        }

    }, [type, queryDropdown])


    const localDateFormat = getLocaleDateString()

    switch (data_type) {
        case "textbox":
            return (
                <TextBoxComponent
                    readOnly={!rest?.editable}
                    placeholder={rest?.placeholder}
                    change={({ value }) => onChange(value)}
                    value={value || rest.column_default}
                />
            );
        case "number":
            return (
                <NumericTextBoxComponent
                    readOnly={!rest?.editable}
                    placeholder={rest?.placeholder}
                    change={({ value }) => onChange(value)}
                    value={value || rest.column_default}
                />
            );
        case "radio":
            return rest?.options.map((e) => (
                <RadioButtonComponent
                    readOnly={!rest?.editable}
                    key={e}
                    label={e}
                    value={e}
                    onChange={(value) => onChange(value)}
                    checked={value === e}
                />
            ));
        // case "dropdown":
        //     const [dropBoxValue, setDropBoxValue] = useState(null);
        //     if (isLoading || !gridList) {
        //         return (
        //             <Player
        //                 autoplay
        //                 loop
        //                 src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
        //                 style={{ height: '60px', width: '60px' }}
        //             />
        //         );
        //     }
        //     if (error) {
        //         return <div>Error: {error.message}</div>;
        //     }
        //     return (
        //         <DropDownListComponent
        //             ref={comboBoxRef}
        //             dataSource={gridList}
        //             fields={fields}
        //             change={(e) => {
        //                 if (e.itemData === null) {
        //                     onChange(null);
        //                 }
        //             }}
        //             select={({ itemData }) => {
        //                 onChange(itemData[fields.value]);
        //             }}
        //             value={value || rest.column_default}
        //         />
        //     );

        case "dropdown":
            if (isLoading || !gridList) {
                return (
                    <Player
                        autoplay
                        loop
                        src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                        style={{ height: '60px', width: '60px' }}
                    />
                );
            }
            if (error) {
                return <div>Error: {error.message}</div>;
            }
            return (
                <ComboBoxComponent
                    ref={comboBoxRef}
                    onClick={() => {
                        comboBoxRef.current?.element.focus();
                    }}
                    dataSource={gridList}
                    fields={fields}
                    allowFiltering={true}
                    filtering={(e) => {
                        if (e.text === '') {
                            e.updateData(gridList); // Show full list if input is empty
                        } else {
                            let filteredData = gridList.filter((item) =>
                                item[fields.text].toLowerCase().indexOf(e.text.toLowerCase()) !== -1
                            );
                            e.updateData(filteredData); // Update ComboBox with filtered data
                        }
                    }}
                    select={({ itemData }) => {
                        onChange(itemData[fields.value]);
                    }}
                    change={(e) => {
                        // When user selects or presses Tab, check for validity
                        if (e.itemData) {
                            let filteredData = gridList.filter((item) =>
                                item[fields.text].toLowerCase().indexOf(e.itemData[fields.text].toLowerCase()) !== -1
                            );
                            // If no match found in the filtered data
                            if (filteredData.length === 0) {
                                onChange(null); // Set to null if invalid
                            }
                        } else {
                            onChange(null); // Set to null if e.itemData is null
                        }
                    }}
                    blur={(e) => {
                        // Handle ComboBox blur event to check if the input is valid when focus is lost (like on Tab press)
                        let inputValue = comboBoxRef.current?.element.value.toLowerCase(); // Get the typed input value
                        let validItem = gridList.find((item) =>
                            item[fields.text].toLowerCase() === inputValue
                        );
                        if (!validItem) {
                            // If input doesn't match any item in the gridList, reset the ComboBox
                            onChange(null);
                            comboBoxRef.current.value = null; // Clear the input field
                        }
                    }}
                    value={value || rest.column_default}

                />
            );


        case "checkbox":
            return (
                <CheckBoxComponent
                    readOnly={rest?.editable}
                    label={rest?.checkboxLabel}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value || rest.column_default}
                />
            );

        case "datetime":

            return (
                <DateTimePickerComponent
                    ref={datePickerRef}
                    onClick={() => {
                        datePickerRef.current?.element.focus();
                    }}
                    format={localDateFormat + ', HH:mm:ss'}
                    id="datepicker"
                    change={({ value }) => {
                        // const formattedValue = value ? moment(value).format('DD/MM/YYYY') : null;
                        // const currentFormat = localDateFormat.toUpperCase() + ' hh:mm a'
                        // const formattedValue = value ? moment(value).format(currentFormat) : null;
                        onChange(value);
                    }}
                    placeholder="Enter date"
                    value={value || rest.column_default}
                >
                </DateTimePickerComponent>
            );

        default:
            return null;
    }
};

export default Input;